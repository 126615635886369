import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
// import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
// import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import WhoWeAre from '../@elegantstack/flow-ui-widgets/WhoWeAre'

const Contact = props => {
    return (<Layout {...props}>
            <Seo title='Kontakt'/>
            <Divider/>
            <Stack>
                <Main>
                    <ContactInfo/>
                    {/*<Divider/>
                    <ContactForm/>*/}
                    <Divider/>
                    <WhoWeAre/>
                </Main>
                <Sidebar>
                    <Commitment/>
                </Sidebar>
            </Stack>
        </Layout>
    )
}

export default Contact;
