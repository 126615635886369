import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Unser Versprechen' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
          Jede Anfrage Ihrerseits ist unverbindlich und wird gleichfalls mit höchster Einsatzbereitschaft beantwortet. Wir begleiten Sie behutsam auf Ihrem Weg zu Ihrer persönlichen Behandlung und Reise, wie und wann Sie es möchten.
      </Text>
    </Card>
  </Section>
)

export default Commitment
