import React from 'react'
import { Card, Text, Link, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const ContactInfo = () => {
  const { phone, email } = useSiteMetadata();
    const mailtoLink = 'mailto:' + email
    const phoneLink = 'tel:' + phone;
    const whatsappLabel = 'WhatsApp';
    const whatsappNumber = '905540289007';
/*
    const whatsappNumber = '491747005633';
*/
    const whatsappLink = 'https://wa.me/' + whatsappNumber;

  return (
    <Section aside title='Kontaktieren Sie uns direkt'>
      <Card variant='paper'>
        <Text variant='p'>Wählen Sie Ihren bevorzugten Weg uns zu kontaktieren.</Text>
          <Link href={whatsappLink} target='_blank'>
              <IconButton variant='simple' role='presentation'>
                  <FaWhatsapp />
              </IconButton>
              {whatsappLabel}
          </Link>
        <Text />
        {phone && (
          <Link href={phoneLink}>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            {phone}
          </Link>
        )}
        <Text />
        {email && (
          <Link href={mailtoLink} target='_blank'>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Link>
        )}
      </Card>
    </Section>
  )
}

export default ContactInfo
