import React from 'react'
import { Card, Text, Link, IconButton } from 'theme-ui'
import {  FaBuilding } from 'react-icons/fa'
import Section from '@components/Section'

const WhoWeAre = () => {

  return (
      <Section aside title='Wer wir sind'>
          <Card variant='paper'>
              <Text variant='p'>Primula Medical Travel ist eine Marke der Pegolas Ventures OÜ.</Text>
              <Text variant='p'>Unsere Firma wurde 2021 gegründet und hat ihre Wurzeln in Antalya, München und Tallin.</Text>
              <Link href='https://www.pegolas.com/the-team' target='_blank'>
                  <IconButton variant='simple' role='presentation'>
                      <FaBuilding />
                  </IconButton>
                  www.pegolas.com/the-team
              </Link>
              <Text variant='p'/>
              <Text variant='p'>Mit Primula Medical Travel wollen wir allen Menschen aus Europa den Zugang zu hochklassigen und erschwinglichen medizinischen Behandlungen ermöglichen.</Text>
              <Text variant='p'>Durch die Möglichkeit diese Behandlungen mit uns im Urlaubsparadies Antalya durchführen zu lassen, können Sie sich jede Behandlung bei Bedarf in Ihren Jahresurlaub integrieren lassen. Wir machen es möglich und planen Alles für Sie. So viel Service möglich, freundlich und seriös, keine versteckten Kosten.</Text>
              <Text variant='p'>Unsere medizinischen Partner sind erfahrene Kliniken mit ausgezeichnetem Renommee. Wir nennen alle Partner mit Namen und haben ein vertraglich vereinbartes Vermarktungssrecht für ihre Leistungen an ausländische Patienten.</Text>
          </Card>
      </Section>
  )
}

export default WhoWeAre
